import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Icon from "@mui/material/Icon";
import Api from "api/api";
import logo from "assets/images/logos/logo.png";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { setDocument, setShowSidenav, setViewContent, useMaterialUIController } from "context";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";

function Sidenav() {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, document, showSidenav, modeMobile, collections } = controller;
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();

  const getDocument = (document) => {
    setDocument(dispatch, document);
    setViewContent(dispatch, false);
    navigate(`${pathname}?c=${searchParams.get("c")}&t=${searchParams.get("t")}&d=${document.id}`);
    setShowSidenav(dispatch, false);
  };

  const getDocuments = () => {
    Api.documents({ collectionId: searchParams.get("c") })
      .then((res) => {
        setDocuments(res.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getDocuments();
  }, [searchParams.get("c")]);

  useEffect(() => {
    if (documents && documents?.length) {
      const document = documents.filter((item) => searchParams.get("d") == item?.id);
      if (document.length > 0) {
        getDocument(document[0]);
      } else {
        getDocument(documents[0]);
      }
    }
  }, [documents]);

  const clickCardCollection = (value) => {
    navigate(`/${value.href}?c=${value.id}&t=${value.name}`);
  };

  const renderSidenavItems = () => {
    return (
      <MDBox
        component="ul"
        display="flex"
        flexDirection="column"
        m={0}
        sx={{
          p: 2,
          listStyle: "none",
        }}
        p={{ sx: 2, sm: 3 }}
      >
        {documents?.map((item, key) => {
          const itemKey = `item-${key}`;
          return (
            <MDBox
              key={itemKey}
              component="li"
              pt={key === 0 ? 0 : 1}
              sx={{
                cursor: "pointer",
              }}
            >
              <MDTypography
                variant="button"
                fontWeight={item.id === document?.id ? "bold" : "regular"}
                sx={({
                  borders: { borderRadius },
                  functions: { pxToRem },
                  palette: { light },
                  transitions,
                }) => ({
                  backgroundColor: item.id === document?.id ? light.main : "transparent",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: borderRadius.md,
                  padding: `${pxToRem(10)} ${pxToRem(16)}`,
                  transition: transitions.create("background-color", {
                    easing: transitions.easing.easeInOut,
                    duration: transitions.duration.shorter,
                  }),
                  "&:hover": {
                    backgroundColor: light.main,
                  },
                })}
                onClick={() => {
                  getDocument(item);
                }}
              >
                <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}>
                  <Icon fontSize="small">{item.icon}</Icon>
                </MDBox>
                {item.name}
              </MDTypography>
            </MDBox>
          );
        })}
      </MDBox>
    );
  };

  return (
    <>
      {modeMobile ? (
        <Drawer
          open={showSidenav}
          onClose={() => {
            setShowSidenav(dispatch, false);
          }}
          anchor="left"
          sx={{
            ".MuiDrawer-paper": {
              height: "100vh",
              minWidth: "20rem",
              borderRadius: 0,
              margin: 0,
              paddingTop: "1rem",
              paddingBottom: "7rem",
            },
          }}
        >
          <MDBox p={2} pb={0}>
            <MDTypography variant="h5" textAlign="center" mb={2}>
              HAVEN
            </MDTypography>
            <img
              src={logo}
              alt=""
              width={"100%"}
              style={{
                borderRadius: "0.75rem",
              }}
              onClick={() => {
                navigate("/dashboard");
                setShowSidenav(dispatch, false);
              }}
            />
          </MDBox>
          <Divider />
          <MDBox px={3}>
            <Grid container spacing={1}>
              {collections?.map((item, key) => {
                return (
                  <Grid key={key} item xs={12}>
                    <MDBox>
                      <Card
                        style={{
                          minWidth: "200px",
                          background: searchParams.get("c") == item.id ? "#dedede" : "transparent",
                        }}
                        onClick={() => clickCardCollection(item)}
                      >
                        <MDBox p={1}>
                          <MDBox display="flex" alignItems="center">
                            <img
                              src={item.image}
                              alt={""}
                              style={{
                                width: "40px",
                                borderRadius: "0.5rem",
                              }}
                            />
                            <MDBox ml={2}>
                              <MDTypography
                                variant="h6"
                                textTransform="capitalize"
                                fontWeight="medium"
                              >
                                {item.name}
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </Card>
                    </MDBox>
                  </Grid>
                );
              })}
            </Grid>
          </MDBox>
          <Divider />
          {renderSidenavItems()}
        </Drawer>
      ) : (
        <Card
          sx={{
            position: "sticky",
            top: "1%",
          }}
        >
          {renderSidenavItems()}
        </Card>
      )}
    </>
  );
}

export default Sidenav;
