import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import { useMaterialUIController } from "context";

export default styled(Drawer)(({ theme, ownerState }) => {
  const { boxShadows, functions, transitions } = theme;
  const { openConfigurator } = ownerState;
  const [controller, dispatch] = useMaterialUIController();
  const { modeMobile } = controller;
  const { lg } = boxShadows;
  const { pxToRem } = functions;

  // drawer styles when openConfigurator={true}
  const drawerOpenStylesPC = () => ({
    width: 360,
    left: "initial",
    right: 0,
    transition: transitions.create("right", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    }),
  });

  const drawerOpenStylesMB = () => ({
    width: "100vw",
    top: "initial",
    bottom: 0,
    transition: transitions.create("bottom", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    }),
  });

  // drawer styles when openConfigurator={false}
  const drawerCloseStylesPC = () => ({
    right: "initial",
    left: pxToRem(-360),
    transition: transitions.create("all", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.enteringScreen,
    }),
  });

  const drawerCloseStylesMB = () => ({
    top: "initial",
    bottom: pxToRem(-95),
    transition: transitions.create("all", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.enteringScreen,
    }),
  });
  return {
    "& .MuiDrawer-paper": modeMobile
      ? {
          height: "100vh",
          margin: 0,
          padding: `0 ${pxToRem(10)}`,
          borderRadius: 0,
          boxShadow: lg,
          overflowY: "auto",
          ...(openConfigurator ? drawerOpenStylesPC() : drawerCloseStylesPC()),
        }
      : {
          width: "100vw",
          height: "auto",
          margin: 0,
          padding: 0,
          borderRadius: 0,
          boxShadow: lg,
          overflowY: "auto",
          ...(openConfigurator ? drawerOpenStylesMB() : drawerCloseStylesMB()),
        },
  };
});
