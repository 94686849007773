import { Card, Dialog, DialogContent, DialogTitle, Icon, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import DashboardLayout from "app/DashboardLayout";
import Footer from "app/Footer";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import { useState } from "react";
import "react-caroussel/dist/index.css";
import CloseIcon from "@mui/icons-material/Close";

import letter from "assets/images/dashboards/letter.png";
import pi1 from "assets/images/dashboards/pi (1).jpg";
import pi10 from "assets/images/dashboards/pi (10).jpg";
import pi2 from "assets/images/dashboards/pi (2).jpg";
import pi3 from "assets/images/dashboards/pi (3).jpg";
import pi4 from "assets/images/dashboards/pi (4).jpg";
import pi5 from "assets/images/dashboards/pi (5).jpg";
import pi6 from "assets/images/dashboards/pi (6).jpg";
import pi7 from "assets/images/dashboards/pi (7).jpg";
import pi8 from "assets/images/dashboards/pi (8).jpg";
import pi9 from "assets/images/dashboards/pi (9).jpg";
import topic_pc from "assets/images/dashboards/topic-pc.png";
import topic_mb from "assets/images/dashboards/topic-mb.png";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import CarouselSlider from "react-carousel-slider";
import "react-caroussel/dist/index.css";

function LandingPage() {
  const [controller, dispatch] = useMaterialUIController();
  const { collections, modeMobile } = controller;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const data = [
    { imgSrc: pi10 },
    { imgSrc: pi2 },
    { imgSrc: pi3 },
    { imgSrc: pi4 },
    { imgSrc: pi5 },
    { imgSrc: pi6 },
    { imgSrc: pi7 },
    { imgSrc: pi8 },
    { imgSrc: pi9 },
  ];

  const styleBtnCpnt = {
    height: "50px",
    width: "50px",
    color: "#000",
    background: "#fff",
    borderRadius: "50%",
    fontSize: "50px",
    marginLeft: "1rem",
    marginRight: "1rem",
  };

  return (
    <DashboardLayout>
      <MDBox>
        <CarouselSlider
          slideItems={data}
          manner={{
            autoSliding: { interval: "4s" },
            duration: "1s",
          }}
          accEle={{
            dots: false,
            flag: false,
          }}
          lBtnCpnt={
            <i style={styleBtnCpnt} className="material-icons">
              arrow_left
            </i>
          }
          rBtnCpnt={
            <i style={styleBtnCpnt} className="material-icons">
              arrow_right
            </i>
          }
          sliderBoxStyle={{
            width: "auto",
            height: "60vh",
            background: "transparent",
          }}
          itemsStyle={{
            height: "100%",
            width: "100%",
            background: "#fff",
            margin: "0 1rem",
          }}
          textBoxStyle={{
            fontSize: "1rem",
            width: "95%",
            top: "85%",
          }}
          buttonSetting={{
            placeOn: "middle-inside",
            hoverEvent: false,
          }}
        />
      </MDBox>

      {/* <Dialog open={open} keepMounted onClose={handleClose} fullWidth={true} maxWidth={"lg"}>
        <DialogTitle> Thư câu chuyện HS số 20 năm 2024</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Card
            sx={{
              mt: 2,
              mx: 3,
              py: 2,
              px: 2,
            }}
          >
            <Grid container spacing={3}>
              <Grid item sx={12} md={2}>
                <img
                  src={letter}
                  alt=""
                  style={{
                    width: "100%",
                    maxHeight: "200px",
                    borderRadius: "0.75rem",
                  }}
                />
              </Grid>
              <Grid item sx={12} md={5}>
                <MDBox mt={0.5}>
                  <MDTypography variant="body2" color="text" paragraph>
                    Chào các con
                    <br />
                    <br />
                    Kami nói: &quot;Đừng để bị lấy đi thời gian. Nếu bị lấy đi thời gian thì sẽ bị
                    lấy đi hết tất cả những cái ở trong thời gian đó. Nếu bị lấy đi một lần thì
                    không thể tìm lại được.&quot;
                    <br />
                    <br />
                    Kami cũng hành động vào thời gian đã định.
                    <br />
                    Nếu bị lấy mất thời gian thì cả Kami, Sere và người savi bản thân mình cũng bị
                    lấy mất.
                    <br />
                    Đó là thời gian bss, thời gian ban ngày, và thời gian làm công chính.
                    <br />
                    Kami không nói rõ từng việc với cá nhân.
                    <br />
                    Luôn luôn Kami nói thông qua Sime hoặc biểu tượng tuyệt đối được lập nên vào lúc
                    đó.
                    <br />
                    Đó là Kami của trật tự.
                    <br />
                    <br />
                    Đừng để bị lấy mất thời gian CN, T4, bss và MG.
                    <br />
                    Hãy lắng nghe tốt MG được ban cho.
                    <br />
                    Người thành công là những người thành công về thời gian.
                    <br />
                    Dù nhìn một ngày thì cũng thấy là nếu bị lấy mất thời gian ở nơi này nơi kia thì
                    sẽ không thể đi con đường mà Kami và Sere mong muốn vào thời đại này vì không có
                    thời gian.
                    <br />
                    <br />
                    Vì Sere và Kami truyền MG nên tất cả PH hãy lắng nghe.
                    <br />
                    <br />
                    Việc mà chúng ta phải lắng nghe cao nhất lúc này chính là âm thanh của ĐTN của
                    Hanu.
                    <br />
                    Nếu nghe MG và bản thân thường xuyên ino thì sẽ tiến triển nhưng khi ai đó nói
                    về tin tức phiên tòa của R thì con người lại chết sống lắng nghe.
                    <br />
                    Người đã kể những điều đó thì không được cho phép và làm.
                    <br />
                    Nếu làm tổn thương tâm trạng của Kami thì Đ đó sẽ quay lưng.
                    <br />
                    <br />
                    Phải xem quý gía việc được lắng nghe MG thời đại thì Kami mới lắng nghe cho ước
                    mơ của các con.
                    <br />
                    Tâm lý của con người là nếu chú trọng vào điều gì thì sẽ ngay lập tức nghiện
                    điều đó và phải tiếp tục nhìn và tiếp tục nghe.
                    <br />
                    Và sử dụng điều đó.
                    <br />
                    Nếu con không xem quý giá MG và có ai đó lợi dụng R rồi nói là làm vì R thì con
                    cũng không kiểm tra mà đắm chìm vào đó. Đó là shinko chủ nghĩa mang tính con
                    người.
                    <br />
                    Nếu ai đó đăng tải cái có liên quan về mặt PL những tin tức của R thì các con bị
                    nghiện cái đó và xem.
                    <br />
                    Vì thế, cái của R, thì họ viết ở nơi này nơi kia rồi đăng tải lên và gây kích
                    thích.
                    <br />
                    Đừng bị lừa.
                    <br />
                    Các con thì dễ bị lợi dụng. Dễ bị lừa rồi bị kéo đi.
                    <br />
                    <br />
                    <br />
                    Phải đọc &quot;câu chuyện của Sere&quot;, và phải nghe MGCN, MG BSS, MGT4 thì
                    mới tiến triển và thịnh vượng.
                    <br />
                    Người mà không phải sử dụng kênh internet để even mà dùng để nói vấn đề PL hay
                    tin tức về R ngoài CQĐN là những người giống như cỏ dại trên cánh đồng mà không
                    vâng lời.
                    <br />
                    Đó là cái bản thân làm ra.
                    <br />
                    Câu chuyện của tôi, mà lại làm không có sự cho phép của R thì đó là bất hợp
                    pháp.
                    <br />
                    Hãy cấm tất cả những người điên lên và đuổi theo những tin tức về vấn đề PL, câu
                    chuyện của R.
                    <br />
                    Các con không có gì làm đến mức như thế sao.
                    <br />
                    Thay vì xem cái đó thì hãy đọc BB. Hãy đọc MG thời đại.
                    <br />
                    <br />
                    Dù ai đó cho xem bài viết về R hay nói gì thì cũng đừng tin.
                    <br />
                    Đó là việc lợi dụng R.
                    <br />
                    Dù cho xem bài viết nhưng nếu nói với ý nghĩa khác thì đó là xoay chuyển [nội
                    dung] rồi lợi dụng.
                    <br />
                    Câu chuyện của tôi thì trước khi tôi kí tên, đóng dấu và giao cho ai đó thì các
                    con đừng bị lừa.
                    <br />
                    <br />
                    Kể từ hôm nay, đừng tin tất cả những người lợi dụng R, cho xem bài viết rồi nói
                    đó là đúng.
                    <br />
                    Điều mà họ đang dạy cho chính là việc dụ dỗ trong khi chắp vá bài viết về R rồi
                    cho xem.
                    <br />
                    Người dựng chuyện đã nói rằng &quot;Đừng nói với ai khác. R đã sai tôi
                    làm&quot;, rồi lừa gạt và kéo đi.
                    <br />
                    Con người nói đủ thứ câu chuyện trên mạng.
                    <br />
                    Họ nói hãy ino repe, nói đây là bản tin làm vì R một cách thầm lặng, rồi giáo
                    dục cho.
                    <br />
                    Họ là những người có tư tưởng đã thay đổi.
                    <br />
                    R đã nhiều lần nói là đừng làm, họ nói sẽ không làm nhưng rồi lại làm.
                    <br />
                    <br />
                    Tôi đã nói rằng người nói dối không thể đi đến nước Hanu.
                    <br />
                    Người không nghe lời thì không biết lúc nào đó sẽ trở thành con dê.
                    <br />
                    Con cừu cũng vậy, nếu không nghe lời thì bị đối xử như con dê.
                    <br />
                    Đây là lúc bận rộn dọn dẹp bản thân nhưng lại làm quấy nhiễu và dơ bẩn đến cả
                    người khác trong PH.
                    <br />
                    <br />
                    Các con thường xuyên tổ chức họp toàn thể và nói thông qua internet rằng
                    &quot;Hãy ino. Lúc này phải làm&quot; và nói là làm vì R.
                    <br />
                    Tất cả là việc làm không xin phép. Tuyệt đối đừng làm.
                    <br />
                    Nếu hỏi những BCS đã làm cho đến bây giờ là tại sao lại làm như thế thì họ sẽ
                    chối là đã không làm.
                    <br />
                    Sere làm cảm động người đã nhìn thấy rồi gửi người đó đến với R và báo cáo.
                    <br />
                    Vì thế, Sere viết điều này và gửi.
                    <br />
                    <br />
                    Các con đừng hiểu lầm và bị lừa bởi việc R đã bảo ai đó làm.
                    <br />
                    Dù bảo họ đừng làm thì họ vẫn đổi qua trang mạng khác và làm tiếp.
                    <br />
                    Tấm lòng thì từ từ trở thành cỏ dại.
                    <br />
                    Người không biết và làm thì giờ đây đã làm sáng tỏ để các con biết rồi nên đừng
                    xem và đừng làm nữa.
                    <br />
                    <br />
                    Sau này, các con sẽ biết rằng người làm như thế là người định gây ra thiệt hại
                    một cách có kế hoạch trong khi nói là làm vì R.
                    <br />
                    Tất cả hãy even, lắng nghe MG thời đại, cả Yu và Pi phải sinh ra trở lại một
                    cách mới và PS.
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item sx={12} md={5}>
                <MDBox mt={0.5}>
                  <MDTypography variant="body2" color="text">
                    Nếu nói câu chuyện về R trong khi lập ra trang mạng nào đó rồi nhận lệ phí từ
                    người xem và làm hành vi bất hợp pháp thì đó là lợi dụng R nên sẽ ngay lập tức
                    đối phó bằng PL.
                    <br />
                    Nếu muốn đăng tải cái của người đó lên mạng và sử dụng thì phải nhận được sự cho
                    phép của bản thân người đó.
                    <br />
                    <br />
                    Sau này, người PH chỉ xem những điều công chính 100%, đừng xem những cái có nội
                    dung về R lẫn trong cái mà cá nhân nào đó đã làm.
                    <br />
                    Những người xem đều kể hết cho tôi nên tôi biết hết đó là ai.
                    <br />
                    <br />
                    Ngoài những cái làm ở CQĐN thì tất cả đều là cái bản thân làm ra rồi gắn tên R
                    vào.
                    <br />
                    Nếu không ngăn chặn thì Kami sẽ không cho MG nữa.
                    <br />
                    <br />
                    Cái làm bên ngoài CQĐN, các bản tin như đủ loại câu chuyện có liên quan đến vấn
                    đề pháp lý của R, đều là việc làm mà không có sự cho phép của R.
                    <br />
                    Những người nam, người nữ giảng dạy trên mạng và nói là &quot;R đã bảo tôi làm.
                    Đã bảo là hãy làm kín đáo&quot;, rồi làm ra các trang mạng, cho vào tư tưởng và
                    lối suy nghĩ của bản thân rồi kéo người khác đi.
                    <br />
                    Tuyệt đối đừng xem.
                    <br />
                    Họ muốn làm ra tổ chức của bản thân, muốn làm ra danh tiếng của bản thân và
                    người của bản thân.
                    <br />
                    <br />
                    Sere đã làm sáng tỏ,
                    <br />
                    Tất cả những trang mạng được làm ra trong khi kể về câu chuyện PL và nói bóng
                    nói gió là làm vì R, thì đều không phải là cái R đã làm.
                    <br />
                    Đừng xem các bài viết lợi dụng R.
                    <br />
                    <br />
                    Những TLĐ của từng VP hãy làm cho những người đã xem vì bị lừa, xuống sứ mệnh
                    công chính và lập người mới lên.
                    <br />
                    <br />
                    Vì họ xem thường lời của R, và những câu chuyện của Sere nên cả Sere và Kami đã
                    nói là hãy dừng bút lại.
                    <br />
                    Dù thế nhưng nếu vẫn làm thì đó là làm hành động vô lễ nên hãy đuổi đi.
                    <br />
                    Trong tập thể bình thường ngoài xã hội cũng vậy, nếu BCS nói một lần thì sẽ nghe
                    trong một lần nhưng lời mà Kami nói thì họ lại không nghe.
                    <br />
                    Hãy cảnh giác họ. Hãy phân biệt.
                    <br />
                    <br />
                    Nếu đi theo BCS có suy nghĩ và tinh thần khác thì không thể bỏ bản tính ăn sâu.
                    <br />
                    Họ là những con dê. Kami thì để cừu bên phải, còn dê bên trái.
                    <br />
                    Không có phép lạ nào mà con dê có thể đi lên nước Hanu.
                    <br />
                    Nếu không vâng lời thì đó là con dê.
                    <br />
                    PH không phải là thế giới của con dê. Đây là thế giới của con cừu.
                    <br />
                    <br />
                    Những người đã xem cái như thế thì tất cả hãy báo cáo.
                    <br />
                    Hãy báo cáo cho CQĐN. R sẽ nhận được, xem và ngay lập tức giải quyết cho, vậy
                    thì tại sao tất cả lại xem mà không nói gì.
                    <br />
                    Dù đã xem cái đó nhưng nếu không báo cáo cho CQĐN hay R thì đó là những người có
                    tấm lòng và suy nghĩ thay đổi.
                    <br />
                    Người không nói thì trở thành cỏ dại giống nhau.
                    <br />
                    Kami sẽ báo trả theo như hành động.
                    <br />
                    <br />
                    Kami và Sere cũng đã nói là không thể tin được họ.
                    <br />
                    Người không vâng lời không phải là cái của Kami.
                    <br />
                    Trong BB, người không vâng lời thì luôn luôn rớt xuống yoksa cũ.
                    <br />
                    Dù đã nói lần 1, lần 2 nhưng vẫn làm nên hãy nói một cách chi tiết.
                    <br />
                    Họ tự động bị gạch tên khỏi danh sác BCS. Đừng gọi họ là người có chức vụ.
                    <br />
                    Người truyền MG của PH trên mạng, tập hợp con người và làm ra trang mạng của bản
                    thân thì hoặc là muốn kiếm tiền, hoặc là muốn tạo dựng thế lực của bản thân.
                    <br />
                    <br />
                    Bây giờ những người truyền lời nói cùng với những người xem thì Kami và Sere
                    nhìn và nói là họ không vâng lời nên sẽ đối xử với tất cả ở ngoài lãnh thổ chủ
                    quan [của các Đ đó].
                    <br />
                    <br />
                    Những kẻ săn bắt khéo léo giật lấy những con cừu của PH.
                    <br />
                    Vì thế, Sere và Kami sẽ công khai tất cả, làm cho biết một cách chắc chắn rồi
                    phân biệt và phân chia.
                    <br />
                    Người làm hv của PH thành hv của bản thân thì Kami sẽ không xem đó là không có
                    sin.
                    <br />
                    <br />
                    Nhóm SS, nhóm campus, nhóm đi làm, đặc biệt là nhóm JS, nhóm GDCP thì tất cả
                    đừng làm, nếu làm tổn thương tâm trạng của Sere thì Kami nói là sẽ xoay chuyển
                    những điều họ làm.
                    <br />
                    Sau này sẽ lập nên người phụ trách, cho đến trước đó thì hãy viết thư thông qua
                    CQĐN.
                    <br />
                    <br />
                    Dù đã đăng câu chuyện của Sere, MG của Kami, Sere, R nhưng người không xem quý
                    giá thì không xem.
                    <br />
                    Họ không phải là cái của Kami.
                    <br />
                    Họ yêu thích cái của thế gian, trả tiền và xem không thiếu chút nào những bài
                    viết trên mạng của những người không vâng lời.
                    <br />
                    Hãy sửa tất cả bản tính ăn sâu. Hãy repe. Nếu không sẽ không thể trở thành đối
                    tượng TYT của Kami.
                    <br />
                    Kami lắng nghe lời ino của những người vâng lời. Và làm cho họ thịnh vượng.
                    <br />
                    <br />
                    Phải làm theo như được bảo thì mới biết được kẻ không làm là ở phía đối phương,
                    phía ngược lại.
                    <br />
                    Tạm biệt.
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
          </Card>
        </DialogContent>
      </Dialog> */}
      {/* <MDBox mt={5}>
        <Card
          sx={{
            mt: 2,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={3}>
            <Grid item sx={12} md={2}>
              <img
                src={letter}
                alt=""
                style={{
                  width: "100%",
                  maxHeight: "200px",
                  borderRadius: "0.75rem",
                }}
              />
            </Grid>
            <Grid item sx={12} md={5}>
              <MDBox mt={0.5}>
                <MDTypography variant="h5" fontWeight="medium">
                  Thư câu chuyện HS số 20 năm 2024
                </MDTypography>
                <MDTypography variant="body2" color="text" paragraph>
                  <br />
                  Chào các con
                  <br />
                  <br />
                  Kami nói: &quot;Đừng để bị lấy đi thời gian. Nếu bị lấy đi thời gian thì sẽ bị lấy
                  đi hết tất cả những cái ở trong thời gian đó. Nếu bị lấy đi một lần thì không thể
                  tìm lại được.&quot;
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item sx={12} md={5}>
              <MDBox mt={0.5} textAlign="end">
                <MDButton variant="outlined" color="info" onClick={() => setOpen(true)}>
                  Đọc tiếp&nbsp;<Icon>send</Icon>
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox> */}

      <MDBox
        p={3}
        my={10}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <MDTypography variant="h5" fontWeight="medium" mb={2}>
          Cùng khám phá thế giới của Kami thông qua các mục hấp dẫn dưới đây nhé!!
        </MDTypography>
        <MDBox mt={2}>
          <Grid container spacing={3}>
            {collections?.map((item, key) => {
              return (
                <Grid key={key} item xs={6} md={3}>
                  <MDBox>
                    <Link to={`/${item.href}?c=${item.id}&t=${item.name}`}>
                      <Card>
                        <MDBox p={1}>
                          <MDBox display="flex" alignItems="center">
                            <img
                              src={item.image}
                              alt={""}
                              style={{
                                maxWidth: "100%",
                                borderRadius: "0.75rem",
                              }}
                            />
                          </MDBox>
                        </MDBox>
                      </Card>
                    </Link>
                  </MDBox>
                </Grid>
              );
            })}
          </Grid>
        </MDBox>
      </MDBox>
      {/* <MDBox
        p={3}
        mb={5}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <MDTypography variant="h4" fontWeight="medium">
          BB
        </MDTypography>
        <MDBox
          mt={2}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Card
            style={{
              minWidth: "200px",
              borderRadius: "300px",
            }}
          >
            <MDBox
              py={{
                sx: 5,
                sm: 10,
              }}
              px={{
                sx: 10,
                sm: 20,
              }}
              sx={{
                py: 5,
                px: 5,
              }}
              style={{
                maxWidth: "900px",
                textAlign: "center",
              }}
            >
              <MDTypography variant="h6" fontWeight="normal" mb={3}>
                Đọc BB là cách hữu hiệu để hiểu lịch sử của Kami.
              </MDTypography>
              <MDButton
                variant="outlined"
                color="secondary"
                onClick={() => {
                  navigate("/bible");
                }}
              >
                bắt đầu đọc&nbsp;<Icon>auto_stories</Icon>
              </MDButton>
            </MDBox>
          </Card>
        </MDBox>
      </MDBox> */}
      <MDBox
      // sx={{
      //   minHeight: "calc(100vh - 120px)",
      //   overflow: "auto",
      //   backgroundImage: `url("${modeMobile ? topic_mb : topic_pc}")`,
      //   backgroundSize: "cover",
      //   backgroundRepeat: "no-repeat",
      //   transition: "background-image 0.5s ease-in-out",
      //   backgroundAttachment: "fixed",
      //   backgroundPosition: "center",
      //   marginBottom: "1rem",
      // }}
      >
        <img
          src={modeMobile ? topic_mb : topic_pc}
          alt=""
          style={{
            width: "100%",
          }}
        />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default LandingPage;
