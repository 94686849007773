import { Card } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import BaseLayout from "app/BaseLayout";
import bible from "assets/pdf/bible.pdf";
import MDBox from "components/MDBox";
import { useEffect } from "react";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

function Bible() {
  useEffect(() => {}, []);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    <BaseLayout>
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Card id="profile">
                    <MDBox style={{ height: "100%" }}>
                      {bible && (
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                          <Viewer fileUrl={bible} plugins={[defaultLayoutPluginInstance]} />
                        </Worker>
                      )}
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default Bible;
