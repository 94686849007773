import { Grid } from "@mui/material";
import DashboardLayout from "app/DashboardLayout";
import DashboardNavbar from "app/DashboardNavbar";
import Footer from "app/Footer";
import Sidenav from "app/Sidenav";
import TitleSection from "app/TitleSection";
import breakpoints from "assets/theme/base/breakpoints";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function BaseLayout({ children }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [controller, dispatch] = useMaterialUIController();
  const { document, modeMobile } = controller;

  const { pathname } = useLocation();

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox>
        <Grid container spacing={3}>
          {pathname === "/profile" || pathname === "/bible" ? (
            <Grid item xs={12}>
              {children}
            </Grid>
          ) : (
            <>
              {modeMobile ? (
                <Sidenav />
              ) : (
                <Grid item xs={12} lg={3}>
                  <MDBox mb={3}>
                    <Sidenav />
                  </MDBox>
                </Grid>
              )}
              <Grid item xs={12} lg={9}>
                <MDBox
                  mb={3}
                  sx={{
                    height: !modeMobile ? "calc(100vh - 170px)" : null,
                    overflowY: "auto",
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TitleSection>{document?.name}</TitleSection>
                    </Grid>
                    <Grid item xs={12}>
                      {children}
                    </Grid>
                  </Grid>
                </MDBox>
              </Grid>
            </>
          )}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

// Typechecking props for BaseLayout
BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BaseLayout;
