import HeaderSticky from "app/HeaderSticky";
import MDBox from "components/MDBox";
import { setLayout, useMaterialUIController } from "context";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function DashboardLayout({ children }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  return (
    <MDBox>
      <HeaderSticky />
      <MDBox
        p={{ sx: 2, sm: 3 }}
        pt={0}
        sx={{
          p: 2,
          pt: 0,
          position: "relative",
          marginTop: "75px",
        }}
      >
        {children}
      </MDBox>
    </MDBox>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
