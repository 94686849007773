import { Grid } from "@mui/material";
import DefaultProjectCard from "app/DefaultProjectCard";
import MediaGridViewer from "app/MediaGridViewer";
import MediaListViewer from "app/MediaListViewer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import PropTypes from "prop-types";

function MediaMixViewer({ contents, videos, audios, posts, contentType }) {
  const [controller, dispatch] = useMaterialUIController();
  const { documentId, document } = controller;

  return (
    <>
      <Grid item xs={12} md={6} xl={4}>
        <DefaultProjectCard document={contents} isClick={false} />
      </Grid>
      <Grid item xs={12} md={6} xl={8}>
        {videos && videos.length > 0 && (
          <>
            <MDBox pb={1}>
              <MDTypography variant="h6">File video</MDTypography>
            </MDBox>
            <MediaGridViewer media={videos} contentType={1} />
          </>
        )}

        {audios && audios.length > 0 && (
          <>
            <MDBox pb={1}>
              <MDTypography variant="h6">File nghe</MDTypography>
            </MDBox>
            <MediaListViewer media={audios} textBtn={"Nghe"} contentType={2} />
          </>
        )}

        {posts && posts.length > 0 && (
          <>
            <MDBox pb={1}>
              <MDTypography variant="h6">Văn bản</MDTypography>
            </MDBox>
            <MediaListViewer media={posts} textBtn={"Đọc"} contentType={3} />
          </>
        )}
      </Grid>
    </>
  );
}

// Setting default values for the props of DefaultProjectCard
MediaMixViewer.defaultProps = {};

// Typechecking props for the DefaultProjectCard
MediaMixViewer.propTypes = {
  contents: PropTypes.any,
  videos: PropTypes.any,
  audios: PropTypes.any,
  posts: PropTypes.any,
  contentType: PropTypes.number.isRequired,
};

export default MediaMixViewer;
