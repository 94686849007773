import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";
import typography from "assets/theme/base/typography";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";

function Footer() {
  const { size } = typography;

  return (
    <MDBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
    >
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={1.5}
      >
        Phiên bản v1.0.1, {new Date().getFullYear()}, bản quyền thuộc về
        <MDTypography variant="button" fontWeight="medium">
          &nbsp;PHVN.&nbsp;
        </MDTypography>
        Cấm lưu - quay hình - sao chép dưới mọi hình thức
      </MDBox>
    </MDBox>
  );
}

export default Footer;
