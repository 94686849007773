// api/productApi.js
import axiosClient from "./axiosClient";

const Api = {
  login: (data) => {
    const url = `authentication/login`;
    return axiosClient.post(url, {
      ...data,
    });
  },
  collections: () => {
    const url = `collections`;
    return axiosClient.get(url);
  },
  documents: (params) => {
    const url = `documents`;
    return axiosClient.get(url, {
      params: params,
    });
  },
  contents: (data) => {
    const url = `contents`;
    return axiosClient.post(url, {
      ...data,
    });
  },
  post: (params) => {
    const url = `post`;
    return axiosClient.get(url, {
      params: params,
    });
  },
  me: () => {
    const url = `users/me`;
    return axiosClient.get(url);
  },
};
export default Api;
