import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import PropTypes from "prop-types";
import { useRef } from "react";
import ReactPlayer from "react-player";

function VideoPlayer({ videoUrl, thumbnail, open, setOpen, title }) {
  const playerRef = useRef();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      fullWidth={true}
      // maxWidth={"lg"}
      fullScreen
    >
      <DialogTitle>{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <MDBox>
          <ReactPlayer
            width={"100%"}
            height={"auto"}
            playing={open}
            playsinline
            url={videoUrl}
            controls
            light={thumbnail}
            ref={playerRef}
            stopOnUnmount={false}
            onEnded={() => setOpen(false)}
            config={{
              file: {
                attributes: {
                  controlsList: "nodownload",
                },
              },
            }}
          />
        </MDBox>
      </DialogContent>
    </Dialog>
  );
}
VideoPlayer.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  thumbnail: PropTypes.string,
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default VideoPlayer;
