import { useEffect, useMemo, useState } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import { ThemeProvider } from "@mui/material/styles";
import MDBox from "components/MDBox";

import AudioDrawer from "app/AudioDrawer";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import routes from "routes";
import { setMiniSidenav, setOpenConfigurator, useMaterialUIController } from "context";
import { Toaster } from "react-stacked-toast";
import Cookies from "js-cookie";
import MusicPlayer from "app/MusicPlayer";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    modeMobile,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Change the openConfigurator state
  const handleAudioDrawerOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    if (!Cookies.get("access_token")) {
      navigate("/sign-in");
    }
  }, []);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const mediaButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="dark"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={20}
      color="white"
      sx={{ cursor: "pointer" }}
      onClick={handleAudioDrawerOpen}
    >
      <img
        alt=""
        src="https://haven.haroot.info/images/Collections/Music.gif"
        style={{ width: "70px", borderRadius: "0.75rem" }}
      />
    </MDBox>
  );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <Toaster />
      {/* {layout === "dashboard" && (
        <>
          <AudioDrawer />
          {mediaButton}
        </>
      )} */}
      {/* {layout === "vr" && <Dashboard />} */}

      {/* {modeMobile ? (
        <>
          <AudioDrawer />
          {mediaButton}
        </>
      ) : (
        <>{pathname !== "/sign-in" && renderMusic}</>
      )} */}
      {layout === "dashboard" && (
        <>
          <AudioDrawer />
          {mediaButton}
        </>
      )}

      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </ThemeProvider>
  );
}
