import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import { useMaterialUIController, setDocument, setViewContent } from "context";

function DefaultProjectCard({ document, isClick }) {
  const [controller, dispatch] = useMaterialUIController();
  const { documentId } = controller;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const openContent = () => {
    if (isClick) {
      setViewContent(dispatch, true);
      navigate(
        `${pathname}?c=${searchParams.get("c")}&t=${searchParams.get("t")}&d=${document.id}`
      );
    }
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "visible",
        cursor: "pointer",
      }}
      onClick={openContent}
    >
      <MDBox position="relative" width="100.25%" shadow="xl" borderRadius="xl">
        <CardMedia
          src={document.image}
          component="img"
          title={document.name}
          sx={{
            maxWidth: "100%",
            margin: 0,
            boxShadow: ({ boxShadows: { md } }) => md,
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
      </MDBox>
      <MDBox mt={1} mx={0.5}>
        <MDTypography variant="button" fontWeight="regular" color="text" textTransform="capitalize">
          {document.level}
        </MDTypography>
        <MDBox mb={1}>
          <MDTypography variant="h5" textTransform="capitalize">
            {document.name}
          </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="button" fontWeight="light" color="text">
            {document.description}
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of DefaultProjectCard
DefaultProjectCard.defaultProps = {
  isClick: true,
};

// Typechecking props for the DefaultProjectCard
DefaultProjectCard.propTypes = {
  document: PropTypes.any.isRequired,
  isClick: PropTypes.bool,
};

export default DefaultProjectCard;
