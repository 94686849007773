import { Divider, Tooltip } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Breadcrumbs from "app/Breadcrumbs";
import blackChair from "assets/images/ecommerce/black-chair.jpeg";
import breakpoints from "assets/theme-dark/base/breakpoints";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import {
  setDarkMode,
  setModeMobile,
  setShowSidenav,
  setTransparentNavbar,
  useMaterialUIController,
} from "context";
import NotificationItem from "examples/Items/NotificationItem";
import {
  navbar,
  navbarContainer,
  navbarIconButton,
  navbarRow,
} from "examples/Navbars/DashboardNavbar/styles";
import Cookies from "js-cookie";
import ProductCell from "layouts/ecommerce/products/product-page/components/ProductCell";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-stacked-toast";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { transparentNavbar, fixedNavbar, darkMode, showSidenav, modeMobile } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const handleDarkMode = () => setDarkMode(dispatch, !darkMode);
  const navigate = useNavigate();

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  useEffect(() => {
    function handleShowSidenav() {
      setModeMobile(dispatch, window.innerWidth < breakpoints.values.lg);
    }

    window.addEventListener("resize", handleShowSidenav);

    handleShowSidenav();

    return () => window.removeEventListener("resize", handleShowSidenav);
  }, [dispatch, location]);

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const signOut = () => {
    handleCloseMenu();
    navigate("/sign-in");
    Cookies.remove("access_token");
    toast.success({
      title: "Thành công",
      description: "Đăng xuất thành công",
    });
  };

  const openSidenav = () => {
    setShowSidenav(dispatch, true);
  };

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <ProductCell image={blackChair} name="Nguyễn Thiên Kỳ" />
      <Divider />
      <NotificationItem
        onClick={() => navigate("/profile")}
        icon={<Icon>assignment_ind</Icon>}
        title="Hồ sơ cá nhân"
      />
      <NotificationItem icon={<Icon>email</Icon>} title="Góp ý cải thiện web" />
      <NotificationItem onClick={signOut} icon={<Icon>logout</Icon>} title="Đăng xuất" />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      mb="3"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox pr={1}>
              <MDInput label="Tìm kiếm" />
            </MDBox>
            <MDBox color={light ? "white" : "inherit"}>
              {modeMobile && (
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  onClick={openSidenav}
                >
                  <Icon sx={iconsStyle}>{showSidenav ? "menu_open" : "menu"}</Icon>
                </IconButton>
              )}
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleDarkMode}
              >
                <Tooltip title={"Chỉnh chế độ xem sáng hoặc tối"}>
                  <Icon sx={iconsStyle}>dark_mode</Icon>
                </Tooltip>
              </IconButton>
              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                // onClick={handleOpenMenu}
              >
                <MDBadge badgeContent={9} color="error" size="xs" circular>
                  <Icon sx={iconsStyle}>notifications</Icon>
                </MDBadge>
              </IconButton> */}
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon sx={iconsStyle}>account_circle</Icon>
              </IconButton>
              {/* <Link to="/authentication/sign-in/basic">
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>
              </Link> */}
              {renderMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
