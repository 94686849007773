import { Card, Divider, Icon } from "@mui/material";
import Grid from "@mui/material/Grid";
import Api from "api/api";
import BaseLayout from "app/BaseLayout";
import FormField from "app/FormField";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";

function Profile() {
  const [profile, setProfile] = useState(null);
  useEffect(() => {
    Api.me()
      .then((res) => {
        setProfile(res.data);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <BaseLayout>
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Card id="profile">
                    <MDBox p={2}>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item>
                          <MDAvatar
                            src={
                              profile?.image ||
                              "https://www.iconpacks.net/icons/2/free-user-icon-3296-thumb.png"
                            }
                            alt="profile-image"
                            size="xl"
                            shadow="sm"
                          />
                        </Grid>
                        <Grid item>
                          <MDBox height="100%" mt={0.5} lineHeight={1}>
                            <MDTypography variant="h5" fontWeight="medium">
                              {profile?.name}
                            </MDTypography>
                            <MDTypography variant="button" color="text" fontWeight="medium">
                              {profile?.church} | {profile?.group}
                            </MDTypography>
                          </MDBox>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card id="basic-info" sx={{ overflow: "visible" }}>
                    <MDBox p={3}>
                      <MDTypography variant="h5">Thông tin cơ bản</MDTypography>
                    </MDBox>
                    <MDBox component="form" pb={3} px={3}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <FormField label="Tên đăng nhập" value={profile?.username} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <FormField label="Giới tính" value={profile?.sex} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <FormField label="Ngày tháng năm sinh" value={profile?.datebirth} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <FormField label="Ngày tháng năm tốt nghiệp" value={profile?.dategrad} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <FormField label="Cụm" value={profile?.cluster} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <FormField label="Địa chỉ" value={profile?.address} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <FormField label="Email" value={profile?.email} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <FormField label="Số điện thoại" value={profile?.phone} />
                        </Grid>
                      </Grid>
                    </MDBox>
                  </Card>
                </Grid>
                {/* <Grid item xs={12}>
                  <Card id="sessions">
                    <MDBox p={3} lineHeight={1}>
                      <MDBox mb={1}>
                        <MDTypography variant="h5">Quản lý thiết bị đăng nhập</MDTypography>
                      </MDBox>
                      <MDTypography variant="button" color="text" fontWeight="regular">
                        Đây là những thiết bị được phép đăng nhập với tài khoản của bạn. Tuyệt đối
                        tôn trọng bản quyền và không chia sẻ thông tin tài khoản cho bất kì ai.
                      </MDTypography>
                    </MDBox>
                    <MDBox pb={3} px={3} sx={{ overflow: "auto" }}>
                      <MDBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width={{ xs: "max-content", sm: "100%" }}
                      >
                        <MDBox display="flex" alignItems="center">
                          <MDBox
                            textAlign="center"
                            color="text"
                            px={{ xs: 0, md: 1.5 }}
                            opacity={0.6}
                          >
                            <Icon fontSize="default">phone_iphone</Icon>
                          </MDBox>
                          <MDBox height="100%" ml={2} lineHeight={1} mr={2}>
                            <MDTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              color="text"
                            >
                              iPhone 11 Pro Max / 192.168.0.1
                            </MDTypography>
                            <MDTypography variant="caption" color="text">
                              Thiết bị đang đăng nhập
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                        <MDBox display="flex" alignItems="center">
                          <MDBadge
                            variant="contained"
                            size="xs"
                            badgeContent="Đang hoạt động"
                            color="success"
                            container
                          />
                          <MDBox mx={2} lineHeight={1}>
                            <MDTypography variant="button" color="secondary" fontWeight="regular">
                              TP. Hồ Chí Minh
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                      <Divider />
                      <MDBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width={{ xs: "max-content", sm: "100%" }}
                      >
                        <MDBox display="flex" alignItems="center" mr={2}>
                          <MDBox
                            textAlign="center"
                            color="text"
                            px={{ xs: 0, md: 1.5 }}
                            opacity={0.6}
                          >
                            <Icon fontSize="default">phone_iphone</Icon>
                          </MDBox>
                          <MDBox ml={2}>
                            <MDTypography
                              display="block"
                              variant="body2"
                              fontWeight="regular"
                              color="text"
                            >
                              iPad Gen 10th / 192.168.2.0
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                        <MDBox display="flex" alignItems="center">
                          <MDBox mx={2} lineHeight={1}>
                            <MDTypography variant="button" color="secondary" fontWeight="regular">
                              TP. Hồ Chí Minh
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid> */}
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default Profile;
