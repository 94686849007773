import {
  FastForwardRounded,
  FastRewindRounded,
  PauseRounded,
  PlayArrowRounded,
  Speed,
  Stop,
  VolumeDownRounded,
  VolumeOff,
  VolumeUp,
  VolumeUpRounded,
} from "@mui/icons-material";
import { Grid, IconButton, Menu, MenuItem, Slider, Stack } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import CatDoiNoiSau from "assets/music/CatDoiNoiSau.mp3";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { setListAudio } from "context";
import { useMaterialUIController, setAudio } from "context";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import ReactHowler from "react-howler";
import toast from "react-stacked-toast";

const speeds = ["4.0", "3.0", "2.0", "1.75", "1.5", "1.25", "1.1", "1.0", "0.75", "0.5", "0.25"];

const speedStyle = {
  fontWeight: 700,
  border: "1px solid #878ba3",
};

const Widget = styled("div")(({ theme }) => ({
  padding: 16,
  borderRadius: 16,
  width: 343,
  maxWidth: "100%",
  margin: "auto",
  position: "relative",
  zIndex: 1,
  background: "linear-gradient(rgb(240 246 255) 0%, rgb(217 217 255) 100%)",
  backdropFilter: "blur(40px)",
}));

const CoverImage = styled("div")({
  width: 100,
  height: 100,
  objectFit: "cover",
  overflow: "hidden",
  flexShrink: 0,
  borderRadius: 8,
  backgroundColor: "rgba(0,0,0,0.08)",
  "& > img": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
});

const TinyText = styled(MDTypography)({
  fontSize: "0.75rem",
  opacity: 0.38,
  fontWeight: 500,
  letterSpacing: 0.2,
});
function MusicPlayer() {
  const [controller, dispatch] = useMaterialUIController();
  const { audio, modeMobile, listAudio } = controller;

  // const [audioPlayer, setAudioPlayer] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const theme = useTheme();
  const [position, setPosition] = useState(0);
  const [duration, setDuration] = useState(0);
  const [valueVolume, setValueVolume] = useState(100);
  const [mute, setMute] = useState(false);
  const [play, setPlay] = useState(false);
  const [speedCurrent, setSpeedCurrent] = useState("1.0");
  const [valuePlayer, setValuePlayer] = useState(0);
  const playerRef = useRef();

  function formatDuration(value) {
    const minute = Math.floor(value / 60);
    const secondLeft = value - minute * 60;
    return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
  }
  const mainIconColor = theme.palette.mode === "dark" ? "#fff" : "#000";
  const lightIconColor =
    theme.palette.mode === "dark" ? "rgba(255,255,255,0.4)" : "rgba(0,0,0,0.4)";
  const handleCloseMenu = () => setOpenMenu(false);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      {speeds?.map((speed) => (
        <MenuItem
          key={speed}
          onClick={() => onChangeRate(speed)}
          style={speed === speedCurrent ? speedStyle : undefined}
        >
          {speed}
        </MenuItem>
      ))}
    </Menu>
  );

  useEffect(() => {
    let interval;
    if (play && playerRef && duration > 0) {
      interval = setInterval(() => {
        const seek = playerRef.current.seek();
        setValuePlayer(((seek / duration) * 100).toFixed(2));
        setPosition(parseInt(seek));
      }, 50);
    }
    return () => clearInterval(interval);
  }, [duration]);

  useEffect(() => {
    if (audio && !play) {
      setPlay(true);
    } else if (!audio) {
      setValuePlayer(0);
      setPlay(false);
      return;
    }
  }, [audio]);

  useEffect(() => {
    let listMusic = JSON.parse(localStorage.getItem("list_music")) || [];
    setListAudio(dispatch, listMusic);
  }, [localStorage.getItem("list_music")]);

  const prevSong = () => {
    previous();
  };

  const nextSong = () => {
    next();
  };

  const stopSong = () => {
    playerRef.current.stop();
    setValuePlayer(0);
    setPlay(false);
  };

  const playOrPause = () => {
    if (audio) {
      setPlay(!play);
    } else {
      toast.error({
        title: "Lỗi",
        description: "Chưa chọn bài hát",
      });
    }
  };

  const onChangeVolumn = (event, newValue) => {
    setValueVolume(newValue);
    if (newValue === 0) {
      onMuteVolumn(true);
    } else {
      onMuteVolumn(false);
    }
  };

  const onChangeValue = (event, newValue) => {
    setValuePlayer(newValue);
    playerRef.current.seek((newValue / 100) * playerRef.current.duration());
  };

  const onMuteVolumn = (value) => {
    setMute(value);
  };

  const onPlay = () => {
    setDuration(playerRef.current.duration());
  };

  const onPause = () => {};

  const onLoad = () => {};

  const next = () => {
    if (listAudio && listAudio?.length > 0 && audio) {
      let currentPosition = listAudio.findIndex((item) => item.id === audio?.id); // Tìm vị trí của phần tử có id=2 trong mảng
      currentPosition = (currentPosition + 1) % listAudio.length;
      setAudio(dispatch, listAudio[currentPosition]);
    }
  };

  const previous = () => {
    if (listAudio && listAudio?.length > 0 && audio) {
      let currentPosition = listAudio.findIndex((item) => item.id === audio?.id); // Tìm vị trí của phần tử có id=2 trong mảng
      currentPosition = (currentPosition - 1 + listAudio.length) % listAudio.length;
      setAudio(dispatch, listAudio[currentPosition]);
    }
  };

  const onEnd = () => {
    setPlay(false);
    setValuePlayer(0);
    next();
  };

  const onStop = () => {
    setPlay(false);
    setValuePlayer(0);
  };

  const onChangeRate = (value) => {
    playerRef.current.rate(parseFloat(value));
    setSpeedCurrent(value);
    handleCloseMenu();
  };

  return (
    <>
      {audio && (
        <ReactHowler
          src={audio?.audioUrl}
          playing={play}
          mute={mute}
          ref={playerRef}
          rate={1}
          onPlay={onPlay}
          onPause={onPause}
          onLoad={onLoad}
          onEnd={onEnd}
          onStop={onStop}
          volume={(valueVolume / 100).toFixed(1)}
          html5={true}
          preload
        />
      )}
      {modeMobile ? (
        <MDBox sx={{ width: "100%", overflow: "hidden" }}>
          <Widget>
            <MDBox sx={{ display: "flex", alignItems: "center" }}>
              <CoverImage>
                <img alt="" src="https://haven.haroot.info/images/Collections/Music.gif" />
              </CoverImage>
              <MDBox sx={{ ml: 1.5, minWidth: 0 }}>
                <MDTypography variant="caption" color="text.secondary" fontWeight={500}>
                  {audio?.description}
                </MDTypography>
                <MDTypography noWrap letterSpacing={-0.25}>
                  <marquee>{<b>{audio?.name}</b>}</marquee>
                </MDTypography>
              </MDBox>
            </MDBox>
            {audio && (
              <>
                <Slider
                  aria-label="time-indicator"
                  size="small"
                  value={valuePlayer}
                  onChange={onChangeValue}
                  sx={{
                    color: theme.palette.mode === "dark" ? "#fff" : "rgba(0,0,0,0.87)",
                    height: 4,
                    "& .MuiSlider-thumb": {
                      width: 8,
                      height: 8,
                      transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
                      "&::before": {
                        boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
                      },
                      "&:hover, &.Mui-focusVisible": {
                        boxShadow: `0px 0px 0px 8px ${
                          theme.palette.mode === "dark"
                            ? "rgb(255 255 255 / 16%)"
                            : "rgb(0 0 0 / 16%)"
                        }`,
                      },
                      "&.Mui-active": {
                        width: 20,
                        height: 20,
                      },
                    },
                    "& .MuiSlider-rail": {
                      opacity: 0.28,
                    },
                  }}
                />
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mt: -2,
                  }}
                >
                  <TinyText>{formatDuration(position)}</TinyText>
                  <TinyText>{formatDuration(parseInt(duration - position))}</TinyText>
                </MDBox>
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mt: -1,
                  }}
                >
                  <IconButton aria-label="previous song" onClick={prevSong}>
                    <FastRewindRounded fontSize="large" htmlColor={mainIconColor} />
                  </IconButton>
                  <IconButton aria-label={!play ? "play" : "pause"} onClick={playOrPause}>
                    {!play ? (
                      <PlayArrowRounded sx={{ fontSize: "3rem" }} htmlColor={mainIconColor} />
                    ) : (
                      <PauseRounded sx={{ fontSize: "3rem" }} htmlColor={mainIconColor} />
                    )}
                  </IconButton>
                  <IconButton aria-label="next song" onClick={nextSong}>
                    <FastForwardRounded fontSize="large" htmlColor={mainIconColor} />
                  </IconButton>
                </MDBox>
                <Stack spacing={2} direction="row" sx={{ mb: 1, px: 1 }} alignItems="center">
                  <Stop htmlColor={mainIconColor} onClick={stopSong} />
                  <Speed htmlColor={mainIconColor} onClick={handleOpenMenu} />
                  {renderMenu()}
                  {mute ? (
                    <VolumeOff htmlColor={mainIconColor} onClick={() => onMuteVolumn(false)} />
                  ) : (
                    <VolumeUp htmlColor={mainIconColor} onClick={() => onMuteVolumn(true)} />
                  )}
                  <VolumeDownRounded htmlColor={lightIconColor} />
                  <Slider
                    aria-label="Volume"
                    onChange={onChangeVolumn}
                    defaultValue={valueVolume}
                    value={valueVolume}
                    sx={{
                      color: theme.palette.mode === "dark" ? "#fff" : "rgba(0,0,0,0.87)",
                      "& .MuiSlider-track": {
                        border: "none",
                      },
                      "& .MuiSlider-thumb": {
                        width: 24,
                        height: 24,
                        backgroundColor: "#fff",
                        "&::before": {
                          boxShadow: "0 4px 8px rgba(0,0,0,0.4)",
                        },
                        "&:hover, &.Mui-focusVisible, &.Mui-active": {
                          boxShadow: "none",
                        },
                      },
                    }}
                  />
                  <VolumeUpRounded htmlColor={lightIconColor} />
                </Stack>
              </>
            )}
          </Widget>
        </MDBox>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <MDBox sx={{ display: "flex", alignItems: "center" }}>
              <MDBox>
                <img
                  alt=""
                  style={{
                    width: "65px",
                    borderRadius: "0.75rem",
                  }}
                  src={"https://haven.haroot.info/images/Collections/Music.gif"}
                />
              </MDBox>
              <MDBox sx={{ ml: 1.5, minWidth: 0 }}>
                <MDTypography variant="caption" color="text.secondary" fontWeight={500}>
                  {audio?.description}
                </MDTypography>
                <MDTypography noWrap letterSpacing={-0.25}>
                  <marquee>{<b>{audio?.name}</b>}</marquee>
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
          {audio && (
            <>
              <Grid item xs={12} lg={4}>
                <MDBox>
                  <Slider
                    aria-label="time-indicator"
                    size="small"
                    value={valuePlayer}
                    onChange={onChangeValue}
                    sx={{
                      color: theme.palette.mode === "dark" ? "#fff" : "rgba(0,0,0,0.87)",
                      height: 4,
                      "& .MuiSlider-thumb": {
                        width: 8,
                        height: 8,
                        transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
                        "&::before": {
                          boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
                        },
                        "&:hover, &.Mui-focusVisible": {
                          boxShadow: `0px 0px 0px 8px ${
                            theme.palette.mode === "dark"
                              ? "rgb(255 255 255 / 16%)"
                              : "rgb(0 0 0 / 16%)"
                          }`,
                        },
                        "&.Mui-active": {
                          width: 20,
                          height: 20,
                        },
                      },
                      "& .MuiSlider-rail": {
                        opacity: 0.28,
                      },
                    }}
                  />
                  <MDBox
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mt: -2,
                    }}
                  >
                    <TinyText>{formatDuration(position)}</TinyText>
                    <TinyText>{formatDuration(parseInt(duration - position))}</TinyText>
                  </MDBox>
                  <MDBox
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: -1,
                    }}
                  >
                    <IconButton aria-label="previous song" onClick={prevSong}>
                      <FastRewindRounded fontSize="large" htmlColor={mainIconColor} />
                    </IconButton>
                    <IconButton aria-label={!play ? "play" : "pause"} onClick={playOrPause}>
                      {!play ? (
                        <PlayArrowRounded sx={{ fontSize: "3rem" }} htmlColor={mainIconColor} />
                      ) : (
                        <PauseRounded sx={{ fontSize: "3rem" }} htmlColor={mainIconColor} />
                      )}
                    </IconButton>
                    <IconButton aria-label="next song" onClick={nextSong}>
                      <FastForwardRounded fontSize="large" htmlColor={mainIconColor} />
                    </IconButton>
                  </MDBox>
                </MDBox>
              </Grid>
              <Grid
                item
                xs={12}
                lg={4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <MDBox
                  sx={{
                    display: "flex",
                    gap: 2,
                    cursor: "pointer",
                  }}
                >
                  {/* <Stack spacing={2} direction="row" sx={{ mb: 1, px: 1 }} alignItems="center"> */}
                  <Stop htmlColor={mainIconColor} onClick={stopSong} />
                  <Speed htmlColor={mainIconColor} onClick={handleOpenMenu} />
                  {renderMenu()}
                  {mute ? (
                    <VolumeOff htmlColor={mainIconColor} onClick={() => onMuteVolumn(false)} />
                  ) : (
                    <VolumeUp htmlColor={mainIconColor} onClick={() => onMuteVolumn(true)} />
                  )}
                  {/* <VolumeDownRounded htmlColor={lightIconColor} />
              <Slider
                aria-label="Volume"
                onChange={onChangeVolumn}
                defaultValue={valueVolume}
                value={valueVolume}
                sx={{
                  color: theme.palette.mode === "dark" ? "#fff" : "rgba(0,0,0,0.87)",
                  "& .MuiSlider-track": {
                    border: "none",
                    width: "100px",
                  },
                  "& .MuiSlider-thumb": {
                    width: 24,
                    height: 24,
                    backgroundColor: "#fff",
                    "&::before": {
                      boxShadow: "0 4px 8px rgba(0,0,0,0.4)",
                    },
                    "&:hover, &.Mui-focusVisible, &.Mui-active": {
                      boxShadow: "none",
                    },
                  },
                }}
              />
              <VolumeUpRounded htmlColor={lightIconColor} /> */}
                  {/* </Stack> */}
                </MDBox>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  );
}

// Setting default values for the props of MusicPlayer
MusicPlayer.defaultProps = {};

// Typechecking props for the MusicPlayer
MusicPlayer.propTypes = {};

export default MusicPlayer;
