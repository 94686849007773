import { Icon } from "@mui/material";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useMaterialUIController, setViewContent } from "context";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

function TitleSection({ children }) {
  const [controller, dispatch] = useMaterialUIController();
  const { viewContent } = controller;
  const navigate = useNavigate();

  const back = () => {
    navigate(-1);
    setViewContent(dispatch, false);
  };

  return (
    <Card>
      <MDBox
        sx={{
          px: 2,
          py: 2,
        }}
        px={{ sx: 2, sm: 3 }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <MDTypography variant="h5" fontWeight="medium">
          {children}
        </MDTypography>
        {viewContent && (
          <Icon
            onClick={back}
            sx={{
              cursor: "pointer",
            }}
          >
            arrow_back
          </Icon>
        )}
      </MDBox>
    </Card>
  );
}

TitleSection.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TitleSection;
