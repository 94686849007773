import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import { useState } from "react";

import MDAvatar from "components/MDAvatar";

import { Icon } from "@mui/material";
import PDFViewer from "app/PDFViewer";
import MDButton from "components/MDButton";
import { setAudio, useMaterialUIController } from "context";
import toast from "react-stacked-toast";

function MediaListViewer({ media, textBtn, contentType, image }) {
  const [controller, dispatch] = useMaterialUIController();
  const { documentId, document } = controller;

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [postUrl, setPostUrl] = useState(0);

  const handleClickOpen = async (value) => {
    if (contentType === 2) {
      setAudio(dispatch, value);
    } else if (contentType === 3) {
      await setTitle(value.name);
      await setPostUrl(value.postUrl);
      await setOpen(true);
    }
  };

  const addListPlay = (value) => {
    if (contentType === 2) {
      let listMusic = JSON.parse(localStorage.getItem("list_music")) || [];
      listMusic.push(value);
      localStorage.setItem("list_music", JSON.stringify(listMusic));
      toast.success({
        title: "Thành công",
        description: (
          <>
            Đã thêm <b>{value.name}</b> vào danh sách phát
          </>
        ),
      });
    }
  };

  const iconStyle = {
    cursor: "pointer",
    marginLeft: "1rem",
  };

  return (
    <Grid item xs={12}>
      {contentType === 3 && (
        <PDFViewer postUrl={postUrl} open={open} setOpen={setOpen} title={title} />
      )}
      <MDBox
        sx={{
          width: "100%",
        }}
      >
        {media?.map((item, key) => {
          return (
            <MDBox
              key={key}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              py={1}
              sx={{
                borderTop: "1px solid #e0e0e0",
                ":first-child": {
                  borderTop: "none",
                  paddingTop: 0,
                },
              }}
            >
              <MDBox display="flex" alignItems="center" mr={1}>
                <MDBox mr={2}>
                  <MDAvatar
                    src={
                      contentType === 2
                        ? "https://haven.haroot.info/images/Collections/Music.png"
                        : image
                        ? image
                        : "https://upload.wikimedia.org/wikipedia/commons/thumb/5/50/Closed_Book_Icon.svg/800px-Closed_Book_Icon.svg.png"
                    }
                    alt={item.name}
                  />
                </MDBox>
                <MDTypography variant="button" fontWeight="medium">
                  {item.name}
                </MDTypography>
              </MDBox>
              <MDBox display="flex">
                {textBtn === "Nghe" && (
                  <>
                    <Icon onClick={() => addListPlay(item)} style={iconStyle}>
                      favorite
                    </Icon>
                    <Icon onClick={() => handleClickOpen(item)} style={iconStyle}>
                      headphones
                    </Icon>
                  </>
                )}
                {textBtn === "Đọc" && (
                  <>
                    <Icon onClick={() => handleClickOpen(item)} style={iconStyle}>
                      auto_stories
                    </Icon>
                  </>
                )}
              </MDBox>
            </MDBox>
          );
        })}
      </MDBox>
    </Grid>
  );
}

// Setting default values for the props of DefaultProjectCard
MediaListViewer.defaultProps = {
  textBtn: "Truy cập",
  contentType: 2,
};

// Typechecking props for the DefaultProjectCard
MediaListViewer.propTypes = {
  media: PropTypes.any.isRequired,
  textBtn: PropTypes.string,
  contentType: PropTypes.number.isRequired,
  image: PropTypes.string,
  // setIsViewContent: PropTypes.func,
  // document: PropTypes.any,
};

export default MediaListViewer;
