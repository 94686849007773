import { Card, Grid } from "@mui/material";
import Api from "api/api";
import BaseLayout from "app/BaseLayout";
import ContentCard from "app/ContentCard";
import DefaultProjectCard from "app/DefaultProjectCard";
import MediaCard from "app/MediaCard";
import MediaGridViewer from "app/MediaGridViewer";
import MediaListViewer from "app/MediaListViewer";
import MediaMixViewer from "app/MediaMixViewer";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

function ContentLayout() {
  const [searchParams] = useSearchParams();

  const [contents, setContents] = useState([]);
  const [videos, setVideos] = useState([]);
  const [posts, setPosts] = useState([]);
  const [audios, setAudios] = useState([]);
  const [contentType, setContentType] = useState(0);
  const [imageMedia, setImageMedia] = useState("");

  const getContents = () => {
    if (searchParams.get("d")) {
      Api.contents({ documentId: searchParams.get("d") })
        .then((res) => {
          const { video, post, audio, contentType, image } = res.data.contents;
          setContents(res.data.childrensDoc || res.data.contents);
          setVideos(video || []);
          setPosts(post || []);
          setAudios(audio || []);
          setContentType(contentType || 0);
          setImageMedia(image);
        })
        .catch((error) => console.log(error));
    }
  };

  useEffect(() => {
    getContents();
  }, [searchParams.get("d")]);

  const renderCard = () => {
    switch (contentType) {
      case 0:
        return contents?.map((item, key) => {
          return (
            <Grid key={key} item xs={12} md={6} xl={3}>
              <DefaultProjectCard document={item} />
            </Grid>
          );
        });
      case 1:
        return <MediaGridViewer media={videos} contentType={contentType} />;
      case 2:
        return <MediaListViewer media={audios} textBtn={"Nghe"} contentType={contentType} />;
      case 3:
        return (
          <MediaListViewer
            media={posts}
            textBtn={"Đọc"}
            contentType={contentType}
            image={imageMedia}
          />
        );

      default:
        return (
          <MediaMixViewer
            contents={contents}
            videos={videos}
            audios={audios}
            posts={posts}
            contentType={contentType}
          />
        );
    }
  };

  return (
    <BaseLayout>
      <Card>
        <MDBox
          display="flex"
          gap={2}
          sx={{
            p: 2,
          }}
          p={{ sx: 2, sm: 3 }}
        >
          <Grid container spacing={3}>
            {renderCard()}
          </Grid>
        </MDBox>
      </Card>
    </BaseLayout>
  );
}

export default ContentLayout;
