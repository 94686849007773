import { Icon } from "@mui/material";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Api from "api/api";
import DashboardNavbar from "app/DashboardNavbar";
import Footer from "app/Footer";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "app/DashboardLayout";
import ComplexProjectCard from "layouts/dashboards/components/ComplexProjectCard";
import Header from "layouts/dashboards/components/Header";
import { useEffect, useState } from "react";
import { useMaterialUIController, setViewContent, setIsMobile } from "context";

function Dashboard() {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, document, isMobile } = controller;

  const [collections, setCollections] = useState([]);

  const getCollections = () => {
    Api.collections()
      .then((res) => {
        setCollections(res.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getCollections();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header />
      <MDBox pb={3}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={7}>
            <MDBox mb={1}>
              <MDTypography variant="h5">Thư câu chuyện HS số 04 năm 2024</MDTypography>
            </MDBox>
            <MDBox mb={2}>
              <MDTypography variant="body2" color="text">
                <br />
                Tất cả đừng chỉ quá thiêu đốt tấm lòng thôi.
                <br />
                Làm sao Sere và Kami tự biết và làm được hả?
                <br />
                Hãy Ino.
                <br />
                Vì làm cho nên hãy cảm tạ.
                <br />
                Và cũng phải repe tất cả những sin mà đã không biết và gây ra thì [những Đ đó] mới
                nhận lời Ino.
                <br />
                Nếu tấm lòng bị tổn thương thì Đ đó sẽ không làm cho thậm chí cái cần phải làm cho.
                <br />
                <br />
                Kami biết và làm nhưng nếu không tin rồi làm trung tâm bản thân thì trái lại sẽ
                thiêu đốt tấm lòng nhiều hơn và cả Kami cùng Sere sẽ tổn thương tấm lòng và tổn
                thương SJ.
                <br />
                <br />
                Các con hãy cùng với tôi, đánh thức Bss và Ino chân thành!
                <br />
                Đối với toàn thể thì đó là cảm tạ và Ino.
                <br />R luôn Ino cho sự sống.
                <br />
                Các con cũng phải Ino.
                <br />
                <br />
                Đừng phân tranh lẫn nhau và đừng chiến đấu bằng huyết và thịt.
                <br />
                Đó là chỉ hành động trong Sere bằng Shinko và bằng Ino.
                <br />
                Tôi chúc các con bình an.
                <br />
                <br />Đ đó chỉ hành động với người hoàn hảo.
                <br />
                Người sai lệch thì hành động theo bản tính thể xác của bản thân.
                <br />S sử dụng Yu đó và hành động nhiều hơn.
                <br />
                <br />
                Nhận thức sai lệch sẽ bị tai nạn.
                <br />
                Sẽ hiểu nhầm và trở nên giống như Giôsia.
                <br />
                Tôi chúc các con bình an.
                <br />
                <br />
                Việc luôn luôn kiểm tra rồi làm là YM của Kami.
                <br />
                Hãy hỏi thử rồi làm.
                <br />
                <br />
                Tôi chúc các con bình an của Kami, Sere, Ima và tôi.
              </MDTypography>
            </MDBox>
          </Grid>
          {/* <Grid item xs={12} md={5} sx={{ textAlign: "right" }}>
            <MDButton variant="outlined" color="info">
              Đọc thêm các thư khác&nbsp;<Icon>send</Icon>
            </MDButton>
          </Grid> */}
        </Grid>
        <MDBox mt={5}>
          <Grid container spacing={3}>
            {collections?.map((collection, index) => (
              <Grid key={index} item xs={12} md={6} lg={4}>
                <MDBox mb={1.5} mt={1.5}>
                  <ComplexProjectCard
                    image={collection.image}
                    title={collection.name}
                    description={collection.description}
                    route={collection.href}
                    id={collection.id}
                  />
                </MDBox>
              </Grid>
            ))}
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
