import Card from "@mui/material/Card";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
function ComplexProjectCard({ color, image, title, description, route, id }) {
  return (
    <Link to={`/${route}?c=${id}&t=${title}`}>
      <Card>
        <MDBox p={2}>
          <MDBox display="flex" alignItems="center">
            <MDAvatar
              src={image}
              alt={title}
              size="xl"
              variant="rounded"
              bgColor={color}
              sx={{
                p: 1,
                mt: -6,
                borderRadius: ({ borders: { borderRadius } }) => borderRadius.xl,
              }}
            />
            <MDBox ml={2} mt={-2} lineHeight={0}>
              <MDTypography variant="h6" textTransform="capitalize" fontWeight="medium">
                {title}
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox my={2} lineHeight={1} textAlign="justify">
            <MDTypography variant="button" fontWeight="light" color="text">
              {description}
            </MDTypography>
          </MDBox>
        </MDBox>
      </Card>
    </Link>
  );
}

// Setting default values for the props of ComplexProjectCard
ComplexProjectCard.defaultProps = {
  color: "dark",
};

// Typechecking props for the ProfileInfoCard
ComplexProjectCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  route: PropTypes.string,
};

export default ComplexProjectCard;
