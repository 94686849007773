import Icon from "@mui/material/Icon";
import MusicPlayer from "app/MusicPlayer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { setOpenConfigurator, useMaterialUIController } from "context";
import AudioDrawerRoot from "app/AudioDrawer/AudioDrawerRoot";
import { useEffect, useState } from "react";
import MDAvatar from "components/MDAvatar";
import { Card, Dialog, DialogContent, DialogTitle, Divider, IconButton } from "@mui/material";
import MDButton from "components/MDButton";
import { setListAudio } from "context";
import { setAudio } from "context";
import { setModeMobile } from "context";
import CloseIcon from "@mui/icons-material/Close";

function AudioDrawer() {
  const [controller, dispatch] = useMaterialUIController();
  const { openConfigurator, darkMode, listAudio, audio, modeMobile } = controller;
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);

  // Use the useEffect hook to change the button state for the sidenav type based on window size.
  useEffect(() => {
    // A function that sets the disabled state of the buttons for the sidenav type.
    function handleDisabled() {
      return window.innerWidth > 1200 ? setDisabled(false) : setDisabled(true);
    }

    // The event listener that's calling the handleDisabled function when resizing the window.
    window.addEventListener("resize", handleDisabled);

    // Call the handleDisabled function to set the state with the initial value.
    handleDisabled();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleDisabled);
  }, []);

  const handleCloseAudioDrawer = () => {
    setOpenConfigurator(dispatch, false);
  };

  const clearListFavoriteMusic = () => {
    localStorage.removeItem("list_music");
    setListAudio(dispatch, []);
  };

  const playAudio = (value) => {
    setAudio(dispatch, value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <AudioDrawerRoot variant="permanent" ownerState={{ openConfigurator }}>
      {modeMobile ? (
        <>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="baseline"
            py={3}
            pl={3}
            style={{
              flex: "0 0 auto",
            }}
          >
            <MDBox>
              <MDTypography variant="h5" fontWeight="medium">
                Âm nhạc
              </MDTypography>
            </MDBox>
            <Icon
              sx={({ typography: { size }, palette: { dark, white } }) => ({
                fontSize: `${size.lg} !important`,
                color: darkMode ? white.main : dark.main,
                stroke: "currentColor",
                strokeWidth: "2px",
                cursor: "pointer",
                transform: "translateY(5px)",
              })}
              onClick={handleCloseAudioDrawer}
            >
              close
            </Icon>
          </MDBox>
          <MDBox
            style={{
              flex: "0 0 auto",
            }}
          >
            <MusicPlayer />
          </MDBox>
          <Divider
            style={{
              flex: "0 0 auto",
            }}
          />
          <MDBox
            style={{
              flexGrow: 1,
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <MDBox
              mb={1}
              px={1}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MDTypography variant="h5" fontWeight="medium">
                Danh sách phát
              </MDTypography>
              {listAudio?.length > 0 ? (
                <MDButton variant="outlined" color="error" onClick={() => clearListFavoriteMusic()}>
                  Xóa hết
                </MDButton>
              ) : (
                <MDButton variant="outlined" color="secondary">
                  Trống
                </MDButton>
              )}
            </MDBox>
            {listAudio?.map((item, key) => {
              return (
                <MDBox
                  key={key}
                  display="flex"
                  alignItems="center"
                  py={1}
                  px={1}
                  sx={{
                    borderTop: "1px solid #e0e0e0",
                    cursor: "pointer",
                    background: audio?.id === item?.id ? "#ebefff" : "transparent",
                    ":first-child": {
                      borderTop: "none",
                      paddingTop: 0,
                    },
                  }}
                  onClick={() => {
                    playAudio(item);
                  }}
                >
                  <MDBox mr={2}>
                    <MDAvatar
                      src="https://haven.haroot.info/images/Collections/Music.gif"
                      alt={item.name}
                    />
                  </MDBox>
                  <MDBox>
                    <MDTypography variant="button" fontWeight="medium">
                      {item.name}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              );
            })}
          </MDBox>
        </>
      ) : (
        <MDBox
          bgColor="linear-gradient(0deg, rgba(201,254,255,1) 0%, rgba(233,207,255,1) 100%)"
          shadow="sm"
          color="white"
          pt={1}
          pl={5}
          pr={12}
          sx={{ position: "relative" }}
          display="flex"
        >
          <Icon
            sx={({ typography: { size }, palette: { dark, white } }) => ({
              fontSize: `30px !important`,
              color: darkMode ? white.main : dark.main,
              stroke: "currentColor",
              strokeWidth: "2px",
              cursor: "pointer",
              transform: "translateY(5px)",
              position: "absolute",
              top: "2rem",
              right: "2rem",
            })}
            onClick={handleCloseAudioDrawer}
          >
            keyboard_double_arrow_down
          </Icon>
          <MusicPlayer />
          <MDBox display="flex" alignItems="center">
            <Dialog open={open} keepMounted onClose={handleClose} fullWidth={true} maxWidth={"sm"}>
              <DialogTitle>Danh sách phát</DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogContent>
                <MDBox
                  style={{
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  <MDBox
                    mb={1}
                    px={1}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {listAudio?.length > 0 ? (
                      <MDButton
                        variant="outlined"
                        color="error"
                        onClick={() => clearListFavoriteMusic()}
                      >
                        Xóa hết
                      </MDButton>
                    ) : (
                      <MDButton variant="outlined" color="secondary">
                        Trống
                      </MDButton>
                    )}
                  </MDBox>
                  {listAudio?.map((item, key) => {
                    return (
                      <MDBox
                        key={key}
                        display="flex"
                        alignItems="center"
                        py={1}
                        px={1}
                        sx={{
                          borderTop: "1px solid #e0e0e0",
                          cursor: "pointer",
                          background: audio?.id === item?.id ? "#ebefff" : "transparent",
                          ":first-child": {
                            borderTop: "none",
                            paddingTop: 0,
                          },
                        }}
                        onClick={() => {
                          playAudio(item);
                        }}
                      >
                        <MDBox mr={2}>
                          <MDAvatar
                            src="https://haven.haroot.info/images/Collections/Music.gif"
                            alt={item.name}
                          />
                        </MDBox>
                        <MDBox>
                          <MDTypography variant="button" fontWeight="medium">
                            {item.name}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    );
                  })}
                </MDBox>
              </DialogContent>
            </Dialog>
            <MDTypography
              variant="button"
              fontWeight="medium"
              sx={{
                cursor: "pointer",
              }}
              onClick={() => setOpen(true)}
            >
              Danh sách phát
            </MDTypography>
          </MDBox>
        </MDBox>
      )}
    </AudioDrawerRoot>
  );
}

export default AudioDrawer;
