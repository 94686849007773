import { ProgressBar, Viewer, Worker } from "@react-pdf-viewer/core";
import Api from "api/api";
import MDBox from "components/MDBox";
import PropTypes from "prop-types";

import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { useEffect, useState } from "react";
import { initBase64Pdf } from "./data";

function PDFViewer({ postUrl, open, setOpen, title }) {
  const [base64Pdf, setBase64Pdf] = useState(initBase64Pdf);

  const handleClose = () => {
    setOpen(false);
    setBase64Pdf(initBase64Pdf);
  };

  // useEffect(() => {
  //   if (postId && open) {
  //     Api.post({
  //       postId: 56,
  //     })
  //       .then((res) => {
  //         setBase64Pdf(res.data.postUrl);
  //       })
  //       .catch((error) => console.log(error));
  //   }
  // }, [postId, open]);

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await fetch(postUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const blob = await response.blob();
        const file = new File([blob], `${name}.pdf`, { type: blob.type });

        viewFilePDF(file);
      } catch (err) {
        console.log(err.message);
      }
    };

    if (open) {
      fetchPdf();
    }
  }, [postUrl, open]);

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const viewFilePDF = async (file) => {
    const selectedFile = file;
    if (selectedFile && selectedFile.type === "application/pdf") {
      const base64 = await fileToBase64(selectedFile);
      setBase64Pdf(base64);
    }
  };

  const transform = (slot) => ({
    ...slot,
    Download: () => <></>,
    DownloadMenuItem: () => <></>,
    Print: () => <></>,
    MoreActionsPopover: () => <></>,
  });

  const renderToolbar = (Toolbar) => <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>;
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
  });
  const { renderDefaultToolbar } = defaultLayoutPluginInstance.toolbarPluginInstance;

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      fullWidth={true}
      // maxWidth={"xxl"}
      fullScreen
    >
      <DialogTitle>{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <MDBox style={{ height: "100%", textAlign: "center" }}>
          {base64Pdf && (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <Viewer
                fileUrl={base64Pdf}
                // plugins={[defaultLayoutPluginInstance]}
                // renderLoader={(percentages) => (
                //   <div style={{ width: "240px" }}>
                //     <ProgressBar progress={Math.round(percentages)} />
                //   </div>
                // )}
              />
            </Worker>
          )}
        </MDBox>
      </DialogContent>
    </Dialog>
  );
}
PDFViewer.propTypes = {
  postUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default PDFViewer;
