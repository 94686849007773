import { FormControl, Grid } from "@mui/material";
import { useState } from "react";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import bgImg from "assets/images/sign-in/bg-img.jpg";
import { useNavigate } from "react-router-dom";

import Cookies from "js-cookie";
import Api from "api/api";
import toast from "react-stacked-toast";
import PageLayout from "examples/LayoutContainers/PageLayout";

function SignIn() {
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  function handleSubmit(event) {
    event.preventDefault();
    if (!username || !password) {
      toast.error({
        title: "Lỗi",
        description: "Tên đăng nhập hoặc mật khẩu không được để trống",
      });
      return;
    }
    Api.login({
      username: username,
      password: password,
      dectectDevice: "string",
    })
      .then((res) => {
        Cookies.set("access_token", res?.data?.token);
        const { hashPassword, role, id, ...user } = res?.data?.user;
        localStorage.setItem("user", JSON.stringify(user));
        navigate("/dashboard");
        setUsername("");
        setPassword("");
        toast.success({
          title: "Thành công",
          description: "Đăng nhập thành công",
        });
      })
      .catch((err) => {
        toast.error({
          title: "Lỗi",
          description: "Đăng nhập thất bại",
        });
      });
  }

  return (
    <PageLayout>
      <MDBox
        position="absolute"
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImg})`,
          // backgroundImage: `url(${bgImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MDBox px={1} width="100%" height="100vh" mx="auto">
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MDBox pt={4} pb={3} px={3}>
                <FormControl error onSubmit={handleSubmit}>
                  <MDBox component="form" role="form">
                    <MDBox mb={2}>
                      <MDInput
                        type="username"
                        label="Tên đăng nhập"
                        fullWidth
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput
                        type="password"
                        label="Mật khẩu"
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </MDBox>
                    {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Ghi nhớ đăng nhập
              </MDTypography>
            </MDBox> */}
                    <MDBox mt={4} mb={1}>
                      <MDButton variant="gradient" color="info" fullWidth type="submit">
                        đăng nhập
                      </MDButton>
                    </MDBox>
                    <MDBox mt={3} mb={1} textAlign="center">
                      <MDTypography variant="button" color="text">
                        Chưa có tài khoản đăng nhập?{" "}
                        <MDTypography
                          // component={Link}
                          // to="/authentication/sign-up/cover"
                          variant="button"
                          color="info"
                          fontWeight="medium"
                          textGradient
                        >
                          Hãy liên hệ trưởng nhà để được cấp
                        </MDTypography>
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </FormControl>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

export default SignIn;
