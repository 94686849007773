// api/axiosClient.js
import axios from "axios";
import queryString from "query-string";
import Cookies from "js-cookie";
import toast from "react-stacked-toast";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "content-type": "application/json",
  },
  paramsSerializer: (params) => queryString.stringify(params),
});

axiosClient.interceptors.request.use(async (config) => {
  // Handle token here ...
  config.headers.Authorization = `Bearer ${Cookies.get("access_token")}`;
  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data;
    }
    return response;
  },

  (error) => {
    if (error && error?.response?.status === 401) {
      window.location.href = `${window.location.origin}/sign-in`;
      Cookies.remove("access_token");
      toast.error({
        title: "Lỗi",
        description: "Đăng nhập lại",
      });
    } else {
      toast.error({
        title: "Lỗi",
        description: "Xin tải lại trang",
      });
    }

    throw error;
  }
);

export default axiosClient;
