import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import { useState } from "react";

import { Card, CardMedia } from "@mui/material";
import VideoPlayer from "app/VideoPlayer";
import { useMaterialUIController } from "context";

function MediaGridViewer({ media, textBtn, contentType }) {
  const [controller, dispatch] = useMaterialUIController();
  const { documentId, document } = controller;
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [mediaUrl, setMediaUrl] = useState("");

  const handleClickOpen = async (value) => {
    if (contentType === 1) {
      await setTitle(value.name);
      await setMediaUrl(value.videoUrl);
      await setOpen(true);
    }
  };

  return (
    <>
      {contentType === 1 && (
        <VideoPlayer videoUrl={mediaUrl} open={open} setOpen={setOpen} title={title} />
      )}
      {media?.map((item, key) => {
        return (
          <Grid key={key} item xs={12} md={6} xl={3}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "transparent",
                boxShadow: "none",
                overflow: "visible",
                cursor: "pointer",
              }}
              onClick={() => handleClickOpen(item)}
            >
              <MDBox position="relative" width="100.25%" shadow="xl" borderRadius="xl">
                <CardMedia
                  src={item?.thumbnail || "https://cdn-icons-png.flaticon.com/512/4237/4237818.png"}
                  component="img"
                  title={item.name}
                  sx={{
                    maxWidth: "100%",
                    margin: 0,
                    boxShadow: ({ boxShadows: { md } }) => md,
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </MDBox>
              <MDBox mt={1} mx={0.5}>
                <MDBox mb={1}>
                  <MDTypography variant="h5" textTransform="capitalize">
                    {item.name}
                  </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0}>
                  <MDTypography variant="button" fontWeight="light" color="text">
                    {item.description}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        );
      })}
    </>
  );
}

// Setting default values for the props of DefaultProjectCard
MediaGridViewer.defaultProps = {
  textBtn: "Truy cập",
  contentType: 2,
};

// Typechecking props for the DefaultProjectCard
MediaGridViewer.propTypes = {
  media: PropTypes.any.isRequired,
  textBtn: PropTypes.string,
  contentType: PropTypes.number.isRequired,
  // description: PropTypes.string,
  // setIsViewContent: PropTypes.func,
  // document: PropTypes.any,
};

export default MediaGridViewer;
