import {
  ClickAwayListener,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grow,
  MenuList,
  Paper,
  Popper,
  Tooltip,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Breadcrumbs from "app/Breadcrumbs";
import blackChair from "assets/images/ecommerce/black-chair.jpeg";
import breakpoints from "assets/theme-dark/base/breakpoints";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import {
  setDarkMode,
  setModeMobile,
  setShowSidenav,
  setTransparentNavbar,
  useMaterialUIController,
  setCollections,
} from "context";
import NotificationItem from "examples/Items/NotificationItem";
import {
  navbar,
  navbarContainer,
  navbarIconButton,
  navbarRow,
} from "examples/Navbars/DashboardNavbar/styles";
import Cookies from "js-cookie";
import ProductCell from "layouts/ecommerce/products/product-page/components/ProductCell";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-stacked-toast";
import logo from "assets/images/logos/logo.png";
import Api from "api/api";
import CloseIcon from "@mui/icons-material/Close";

function DashboardNavbar() {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { transparentNavbar, fixedNavbar, darkMode, showSidenav, modeMobile, collections } =
    controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [user, setUser] = useState(null);
  const [open, setOpen] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const handleDarkMode = () => setDarkMode(dispatch, !darkMode);
  const navigate = useNavigate();
  //   const [collections, setCollections] = useState([]);
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const anchorRef = useRef(null);

  const getCollections = () => {
    Api.collections()
      .then((res) => {
        // setCollections(res.data);
        setCollections(dispatch, res.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getCollections();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("user")) {
      setUser(JSON.parse(localStorage.getItem("user")));
    }
  }, [localStorage.getItem("user")]);

  useEffect(() => {
    // Setting the navbar typeF
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  useEffect(() => {
    function handleShowSidenav() {
      setModeMobile(dispatch, window.innerWidth < breakpoints.values.lg);
    }

    window.addEventListener("resize", handleShowSidenav);

    handleShowSidenav();

    return () => window.removeEventListener("resize", handleShowSidenav);
  }, [dispatch, location]);

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const signOut = () => {
    handleCloseMenu();
    navigate("/sign-in");
    Cookies.remove("access_token");
    localStorage.removeItem("user");
    toast.success({
      title: "Thành công",
      description: "Đăng xuất thành công",
    });
  };

  const openSidenav = () => {
    setShowSidenav(dispatch, true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderImage = (name, image) => {
    return (
      <Dialog open={open} keepMounted onClose={handleClose} fullWidth={true} maxWidth={"sm"}>
        <DialogTitle>{name}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <MDBox>
            <img src={image} alt={name} width={"100%"} />
          </MDBox>
        </DialogContent>
      </Dialog>
    );
  };

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 1 }}
    >
      {renderImage(user?.name, user?.image)}
      <MDBox
        onClick={() => {
          setOpen(true);
        }}
        style={{
          cursor: "pointer",
        }}
      >
        <ProductCell
          image={user?.image || "https://www.iconpacks.net/icons/2/free-user-icon-3296-thumb.png"}
          name={user?.name}
        />
      </MDBox>
      <Divider />
      <NotificationItem
        onClick={() => navigate("/profile")}
        icon={<Icon>assignment_ind</Icon>}
        title="Hồ sơ cá nhân"
      />
      <NotificationItem onClick={signOut} icon={<Icon>logout</Icon>} title="Đăng xuất" />
    </Menu>
  );

  const isRenderMenuBtn = () => {
    if (
      !modeMobile ||
      pathname.includes("dashboard") ||
      pathname.includes("profile") ||
      pathname.includes("bible")
    ) {
      return false;
    }
    return true;
  };

  return (
    <MDBox
      style={{
        background: "#f0f2f5",
        justifyContent: "center",
        border: "1px solid #dedede",
        display: "flex",
        justifyContent: "space-between",
        position: "fixed",
        width: "100vw",
        top: 0,
        zIndex: 10,
      }}
      px={{
        sx: 2,
        sm: 4,
      }}
      sx={{
        px: 2,
        py: 0.3,
      }}
    >
      <MDBox
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => navigate("/dashboard")}
      >
        <img
          src={logo}
          alt=""
          style={{
            height: "70px",
            width: "auto",
            marginRight: "1rem",
            borderRadius: "50%",
          }}
        />
        {!modeMobile && (
          <MDTypography variant="h5" fontWeight="medium">
            HAVEN
          </MDTypography>
        )}
      </MDBox>
      {!modeMobile && (
        <MDBox
          style={{
            display: "flex",
            alignItems: "center",
            gap: "2rem",
          }}
        >
          {collections?.map((item, key) => {
            return (
              <Link key={key} to={`/${item.href}?c=${item.id}&t=${item.name}`}>
                <MDTypography
                  key={key}
                  variant="h6"
                  fontWeight="medium"
                  style={{
                    padding: "0.5rem 1rem",
                    background: searchParams.get("c") == item.id ? "#fff" : "transparent",
                    borderRadius: "50px",
                    boxShadow:
                      searchParams.get("c") == item.id
                        ? "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                        : "none",
                  }}
                >
                  {item.name}
                </MDTypography>
              </Link>
            );
          })}
        </MDBox>
      )}
      <MDBox
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          src={user?.image || "https://www.iconpacks.net/icons/2/free-user-icon-3296-thumb.png"}
          alt=""
          style={{
            height: "50px",
            width: "auto",
            marginRight: "1rem",
            cursor: "pointer",
            borderRadius: "50%",
          }}
          onClick={handleOpenMenu}
        />
        {renderMenu()}
        <MDButton
          variant="outlined"
          color="secondary"
          onClick={() => {
            window.open(
              "https://docs.google.com/forms/d/e/1FAIpQLSd7Nzi4Po7wU5YvjGqwBnA09HWsoVb_yopzPCtGkxriNSxc2Q/viewform"
            );
          }}
        >
          Góp ý
        </MDButton>
        {isRenderMenuBtn() && (
          <IconButton
            size="small"
            disableRipple
            color="inherit"
            sx={navbarIconButton}
            onClick={openSidenav}
          >
            <Icon>{showSidenav ? "menu_open" : "menu"}</Icon>
          </IconButton>
        )}
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {};

export default DashboardNavbar;
