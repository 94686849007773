import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import VideoPlayer from "app/VideoPlayer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import { useState } from "react";

function ContentCard({ content }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <VideoPlayer videoUrl={content.videoUrl} open={open} setOpen={setOpen} title={content.name} />
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "transparent",
          boxShadow: "none",
          overflow: "visible",
          cursor: "pointer",
        }}
        onClick={handleClickOpen}
      >
        <MDBox position="relative" width="100.25%" shadow="xl" borderRadius="xl">
          <CardMedia
            src={
              "https://haven.haroot.info/images/Documents/cuo%CC%A3%CC%82c%20so%CC%82%CC%81ng%20Shinko/Bi%CC%80a/ATLS.png"
            }
            component="img"
            title={content.name}
            sx={{
              maxWidth: "100%",
              margin: 0,
              boxShadow: ({ boxShadows: { md } }) => md,
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
        </MDBox>
        <MDBox mt={1} mx={0.5}>
          <MDBox mb={1}>
            <MDTypography variant="h5" textTransform="capitalize">
              {content.name}
            </MDTypography>
          </MDBox>
          {/* <MDBox mb={1} lineHeight={0}>
            <MDTypography variant="button" fontWeight="light" color="text">
              {content.description}
            </MDTypography>
          </MDBox> */}
        </MDBox>
      </Card>
    </>
  );
}

// Setting default values for the props of ContentCard
ContentCard.defaultProps = {};

// Typechecking props for the ContentCard
ContentCard.propTypes = {
  content: PropTypes.any.isRequired,
};

export default ContentCard;
