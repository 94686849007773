import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import breakpoints from "assets/theme/base/breakpoints";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import CarouselSlider from "react-carousel-slider";
import "react-caroussel/dist/index.css";

function Header({ children }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const data = [];

  const styleBtnCpnt = {
    height: "50px",
    width: "50px",
    color: "#000",
    background: "#fff",
    borderRadius: "50%",
    fontSize: "50px",
    marginLeft: "1rem",
    marginRight: "1rem",
  };

  return (
    <MDBox position="relative" mb={5} mt={2}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="30rem"
        borderRadius="xl"
      >
        <CarouselSlider
          slideItems={data}
          manner={{
            autoSliding: { interval: "5s" },
            duration: "3s",
          }}
          accEle={{
            dots: false,
            flag: false,
          }}
          lBtnCpnt={
            <i style={styleBtnCpnt} className="material-icons">
              arrow_left
            </i>
          }
          rBtnCpnt={
            <i style={styleBtnCpnt} className="material-icons">
              arrow_right
            </i>
          }
          sliderBoxStyle={{
            width: "100%",
            height: "600px",
            background: "transparent",
          }}
          itemsStyle={{
            height: "100%",
            padding: "3px",
            background: "#fff",
          }}
          textBoxStyle={{
            fontSize: "1rem",
            width: "95%",
            top: "85%",
          }}
          buttonSetting={{
            placeOn: "middle-inside",
            hoverEvent: false,
          }}
        />
      </MDBox>

      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar src={""} alt="profile-image" size="xl" shadow="sm" />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                Thông báo{" "}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                <a href="/dashboard">
                  <i>LINK THAM GIA</i>
                </a>
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                <Tab
                  label="Các sự kiện"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      event
                    </Icon>
                  }
                />
                <Tab
                  label="Thông báo"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      notifications
                    </Icon>
                  }
                />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
