import { Grid, Icon } from "@mui/material";
import Card from "@mui/material/Card";
import Not404 from "assets/images/404.png";
import Chrome from "assets/images/chrome.png";
import bgImg from "assets/images/sign-in/bg-universe.jpg";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";

import PageLayout from "app/PageLayout";

function PageNotFound() {
  const navigate = useNavigate();
  const backDashboard = () => {
    navigate("/dashboard");
  };

  return (
    <PageLayout>
      <MDBox
        position="absolute"
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImg})`,
          // backgroundImage: `url(${bgImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MDBox px={1} width="100%" height="100vh" mx="auto">
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MDBox pt={4} pb={3} px={3}>
                <img src={Chrome} alt="" width={"100%"} />
                <MDTypography variant="h5" align="center">
                  HAVEN Website chỉ hỗ trợ trên trình duyệt Chrome
                </MDTypography>
                {/* <MDBox align="center" mt={2}>
                  <MDButton color="info" onClick={backDashboard}>
                    <Icon>arrow_back</Icon>&nbsp;Trở về
                  </MDButton>
                </MDBox> */}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

export default PageNotFound;
